<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="45%"
          height="45%"
          alt="OneBox"
        />
        <br />
        <br />
        <v-progress-linear
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p class="text-center">รอสักครู่</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCookies from "vue-cookies";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    loginwithdomain(
      param_domain,
      param_displayname,
      param_email,
      param_onemailtoken
    ) {
      let payload = {
        domain: param_domain,
        displayName: param_displayname,
        email: param_email,
        accesstoken: param_onemailtoken
      };
      localStorage.removeItem("color");
      this.$store
        .dispatch("authorize_login_business_email_withdomain", payload)
        .then(res => {
          if (res.status === "Access Granted") {
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "bytoken",
            });

            if (this.$route.query.color !== undefined) {
              localStorage.setItem("color", this.$route.query.color);
            }
            this.$router.push("/mydrive");
          } else {
            Toast.fire({ icon: "error", title: res.msg });
            this.$router.push("/");
          }
        })
        .catch(err => {
          console.log(err);
          Toast.fire({ icon: "error", title: res.msg });
          this.$router.push("/");
        });
    },

    async fn_loginbusiness(_accesstoken, _taxid) {
      let payload = {
        taxid: _taxid,
        accesstoken: _accesstoken
      };

      localStorage.clear();
      let encodetoken = window.btoa(_accesstoken)
      // localStorage.setItem("token", encodetoken);
      // localStorage.setItem("token", _accesstoken);
      VueCookies.set('token_auth', true);
      VueCookies.set('token', _accesstoken);
      let check_auth = await this.$store.dispatch(
        "authorize_checking",
        payload
      );
      if (check_auth.status === "Access Granted") {
        this.$store.dispatch("switchaccount_business", payload).then(msg => {
          if (msg.status === "Business Access Granted") {
            this.$router.push({ name: "myfiles" });
          } else {
            this.$router.push("logout");
          }
        });
      } else {
        this.$router.push("logout");
      }
    }
  },
  mounted() {
    if ("accesstoken" in this.$route.query && "taxid" in this.$route.query) {
      this.fn_loginbusiness(
        this.$route.query.accesstoken,
        this.$route.query.taxid
      );
    } else {
      Toast.fire({ icon: "error", title: "Access Denied" });
      this.$router.push("/");
    }
    // this.loginwithsharetoken()
  }
};
</script>
